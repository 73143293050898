import React from 'react';
import { FaBook } from 'react-icons/fa';
function Documentation() {
    return (
        
        <div className="documentation">
            {/* Bouton pour fermer le composant */}
            <a href="/" target="_self" rel="noopener noreferrer">
                    <FaBook /> Acceuil
                </a>

            {/* Le reste de votre contenu HTML ici */}
            <h1>Wallets-Cryptos 🪙</h1>

                <h2>📌 Description</h2>
                <p><strong>Wallets-Cryptos</strong> est un gestionnaire de portefeuille crypto qui permet aux utilisateurs de gérer leurs actifs cryptographiques, d'ajouter des transactions et de visualiser l'historique de leurs transactions et de leur portefeuille.</p>

                <h2>📖 Utilisation</h2>
                <ul>
                    <li>Inscription : Accédez à <code>index.html</code> et remplissez le formulaire d'inscription.</li>
                    <li>Connexion : Utilisez vos identifiants pour vous connecter.</li>
                    <li>Ajout de cryptos : Une fois connecté, utilisez la section "Ajout cryptos" pour ajouter de nouveaux actifs cryptographiques à votre portefeuille.</li>
                    <li>Utilisez l'icône de recherche à côté du champ nom crypto pour rechercher le bon ID de la crypto sur CoinGecko.</li>
                    <li>Ajout de transactions : Utilisez la section "Ajout Transaction" pour ajouter de nouvelles transactions.</li>
                    <li>Historique : Consultez l'historique de vos transactions et de votre portefeuille pour chaque crypto.</li>
                    <li>Les cryptos et les transactions peuvent être sauvegardés dans un fichier pour une restauration ultérieure à l'aide des boutons Sauvegarde et Restauration.</li>
                </ul>

                <h2>💖 Soutien</h2>
                <p>Si vous souhaitez soutenir notre projet, vous pouvez faire un don aux adresses suivantes :</p>
                <ul>
                    <li>Matic : 0x5c2eabcb75b6138bdfe39fd9a0dae0716c6426bc</li>
                    <li>Ethereum (ETH) : 0x5c2eabcb75b6138bdfe39fd9a0dae0716c6426bc</li>
                </ul>
            {/* ... */}
        </div>
    );
};

export default Documentation;